const apiUrl = 'https://api.ocracademy.ca/wp/wp-json'

export async function sendContactForm(contactInfo) {
  const endpoint = `${apiUrl}/contact/v1/send`
  const response = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(contactInfo),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const responseBody = await response.json()
  return responseBody
}