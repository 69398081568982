import React, { useState } from 'react'
import './Contact.scss'
import HeroBanner from '../../components/HeroBanner'
import Layout from '../../components/Layout'
import Recaptcha from 'react-recaptcha'
import {
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Alert
} from 'reactstrap'
import { sendContactForm } from '../../services/ApiService'
import { graphql } from 'gatsby'

const Contact = ({ data }) => {
  const [recaptchaValue, setRecaptchaValue] = useState(null)
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [contactMessage, setContactMessage] = useState('')
  const [contactSubmitted, setContactSubmitted] = useState(false)

  const recaptchaVerified = response => {
    setRecaptchaValue(response)
  }

  const resetRecaptchaValue = () => {
    setRecaptchaValue(null)
  }

  const isFormFilled = () => {
    return (
      contactName.trim() !== '' &&
      contactEmail.trim() !== '' &&
      contactPhone.trim() !== '' &&
      contactMessage.trim() !== '' &&
      recaptchaValue != null
    )
  }

  const handleSubmit = async () => {
    const contactUs = {
      contact_email: contactEmail,
      recaptcha_response: recaptchaValue,
      contact_message: contactMessage,
      contact_name: contactName,
      contact_phone: contactPhone
    }
    const result = await sendContactForm(contactUs)
    if (result.status === 200) {
      setContactSubmitted(true)
      handleReset()
    }
  }

  const handleReset = async () => {
    setContactName('')
    setContactEmail('')
    setContactPhone('')
    setContactMessage('')
  }

  return (
    <Layout>
      <div className="Contact">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="Contact Us"
          caption="Need to get a hold of us?"
        />
        <div className="container content">
          <Row>
            <Col md={6}>
              <h1>Send us a Message</h1>
              <div hidden={!contactSubmitted}>
                <Col>
                  <Alert color="success">
                    Your message has been sent. Thanks for your feedback.
                  </Alert>
                </Col>
              </div>
              <div hidden={contactSubmitted}>
                <Form>
                  <Row>
                    <Col className="col-md-6">
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Input
                          name="name"
                          id="name"
                          onChange={e => setContactName(e.target.value)}
                          value={contactName}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-md-6">
                      <FormGroup>
                        <Label for="phone">Phone</Label>
                        <Input
                          name="phone"
                          id="phone"
                          type="phone"
                          onChange={e => setContactPhone(e.target.value)}
                          value={contactPhone}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                          name="email"
                          id="email"
                          onChange={e => setContactEmail(e.target.value)}
                          value={contactEmail}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="message">Message</Label>
                        <Input
                          name="message"
                          id="message"
                          type="textarea"
                          rows="5"
                          onChange={e => setContactMessage(e.target.value)}
                          value={contactMessage}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Recaptcha
                    sitekey="6LfISL0UAAAAAIGE30LKUxNNMrqtTHtQDgFVX2ZM"
                    verifyCallback={recaptchaVerified}
                    expiredCallback={resetRecaptchaValue}
                  />
                  <Button
                    color="success"
                    disabled={!isFormFilled()}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>{' '}
                  <Button color="secondary" onClick={handleReset}>
                    Reset
                  </Button>
                </Form>
              </div>
            </Col>
            <Col md={6}>
              <h1>Find us in Ottawa</h1>
              <iframe
                title="OCR Academy on Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2804.162443104523!2d-75.7994919845393!3d45.3455330493749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce00cf15552d25%3A0xd781acf2ce0d4e8e!2sOCR%20Academy%20-%20Obstacle%20course%20training!5e0!3m2!1sen!2sca!4v1570902419058!5m2!1sen!2sca"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  {
    file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/09/children.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
